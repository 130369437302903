import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "dealer-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Accruent (Maintenance Connection Inc.) has been an authorized
dealer of MCC products and professional services since 2003,
100’s of their biggest customers use our products. MCC has
been a dealer of their products for the same period. Our team
provides a depth and wealth of experience with MC that you
can’t find anywhere else.`}</p>
    <p>{`We have dealers on 5 continents, bringing products and
services to make the best EAM/CMMS stand even further ahead of the
competition. We have maintained over 90% of our customers
throughout this time.`}</p>
    <p>{`We directly sell, but never compete with our dealers in the
Americas.`}</p>
    <p><strong parentName="p">{`If you are selling Accruent’s CMMS solutions and you want to
give your customers the best experience and optimize your
profit, you need Maintenance Connection Canada on your team.`}</strong></p>
    <h3><strong parentName="h3">{`What do we provide?`}</strong></h3>
    <p>{`We provide software like the award winning MC
Everywhere,`}<em parentName="p">{`true`}</em>{`mobile EAM/CMMS, MC LoginHub and DataHub. User
hosting with low latency, Professional services. For more
details read on, then contact us to become one of our dealers
and have the company with the most MC experience in the world
on your team.`}</p>
    <h3><strong parentName="h3">{`MCe: Mobile EAM/CMMS Everywhere your customers want to work'`}</strong></h3>
    <p>{`Maintenance Connection Everywhere (MCe) has been providing
Accruent and other customers around the world with an award
winning offline/online mobile product. 16 years of experience
syncing the correct data to have a real mobile solution that
works everywhere. Mac, Windows, Android, iOS, Linux & more.
Optimized for Cell phones, tablets, laptops (touch & mouse)`}</p>
    <p>{`WO, Inventory, Inventory Count, Assets even Hierarchical
procedures (that become hierarchical tasks), collapse and
expand, works for multiple levels of experience (give details
for less experienced technician with the same procedure that
is convenient for experienced technicians.)`}</p>
    <p>{`Many advanced features for WO and more, real mobile
optimization, adaptive for different device sizes and user
needs, see our web site
`}<a parentName="p" {...{
        "href": "/dealer"
      }}>{`www.MaintenanceConnection.ca/dealer`}</a>{` or use the QR
code above for more info.`}</p>
    <h4><strong parentName="h4">{`Increase Efficiency & Productivity`}</strong></h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Concurrent and named licenses available on the same system
to meet your particular needs`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`User definable layouts to ensure it works the way you do`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`HTML5 based eliminates the hassle of installing specialty
apps`}</p>
      </li>
    </ol>
    <h4><strong parentName="h4">{`Work Everywhere, Online & Offline – Real Mobile`}</strong></h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Continue to work and retain all data when no connection is
available`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Get access to all MC data when connected to the internet
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Inventory count that works where the inventory is
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Work orders anywhere you need them, even if you are
without internet for a week or more!
`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mangers work on subway or bus to and from the office`}</p>
      </li>
    </ul>
    <h2><strong parentName="h2">{`LoginHub: User management, SSO, true security`}</strong></h2>
    <p>{`We bring full login, user management and SSO through the
`}<strong parentName="p">{`Maintenance Connection LoginHub,`}</strong>{` as we say, user
management and login experience … So much more than just the
SSO checkbox on an RFP. As one customer said “This is what I
expected to get when I asked for SSO”. The SSO part is easy,
it’s all the user management and tools for the IT department
that make LoginHub stand above all other options.`}</p>
    <p>{`Let the maintenance department do what they do best. Stop
forcing them to remember yet another account, yet more
passwords and security and yet more ways for your data to leak
and be compromised. Put security in the hands of the people
who worry about it every day. Ensure contact information for
the service requesters are kept up to date, disable users who
are no longer employed, off load the daily user management
from your highly experienced maintenance staff so they are
able to do their best.`}</p>
    <p>{`Okta, Ping, Azure AD, Active Directory, Auth0 with dozens more
here and coming soon.`}</p>
    <p>{`And in addition to all this, it provides many options for SSO
as well! An included optional feature that almost all LoginHub
customers use. Many bought it for just that feature alone–then
found they needed and wanted the rest.`}</p>
    <p>{`Accruent uses LoginHub for hundreds of their largest
customers.`}</p>
    <h3><strong parentName="h3">{`Maintenance Connection DataHub`}</strong></h3>
    <p>{`For more than a decade, the DataHub has been bringing the
Maintenance Connection family of products together with data
imported on demand from: IoT (before they were called IoT!),
Sensors, Meters, MS Dynamics GP (Great Plains), MS Dynamics
NAV (Navision), SAP Software and … as you would expect from
Maintenance Connection Canada, many more. We are designed to
be flexible for talking to everyone. Template driven setup.
Works with almost any source that can put data out into a
file.`}</p>
    <h3><strong parentName="h3">{`Hosting Services – with customer safe backups`}</strong></h3>
    <p>{`We can provide cloud hosting SaaS solutions for MC-MRO,
MC-Express, MCe, LoginHub, DataHub and GISHub. We put servers
near the customers to reduce latency.`}</p>
    <p>{`We have developed 1 click upgrades and greatly simplified
installs of MC and all our products for customers not on
Accruent servers, yes even for MRO. We have been doing this
since 2003.`}</p>
    <p>{`We provide full backup for the companies using these services
so that, while we have never had it happen, if and when a
disaster such as ransomware occurs, there are multiple
off-site disconnected backups. We also give customers access
to their backups, we believe that your data belongs to you,
not to who ever is hosting your data.`}</p>
    <p>{`We provide backup service also to our on-prem customers two of
which have been hit by ransomware and we were able to get them
up and running again within hours with their EAM/CMMS data (they
weren’t so lucky with some of their other software – their
backup strategies were compromised.)`}</p>
    <h3><strong parentName="h3">{`Professional services with people who have years experience`}</strong></h3>
    <p>{`Our professional services bring custom reports, install and
other support to your customers if that is not your area of
expertise. Several on our team have been with MC since 2003 to
2005. We know MC inside and out.`}</p>
    <p>{`Accruent sells our software, our install services and our
other professional services and has since the beginning. Talk
to us about how we can help you increase your income with
happier customers.`}</p>
    <p>{`Contact us for details: `}<a parentName="p" {...{
        "href": "mailto:peter@MaintenanceConnection.ca"
      }}>{`peter@MaintenanceConnection.ca`}</a>{` for
details of our offerings and how to become a dealer of our
products.`}</p>
    <p><strong parentName="p">{`Add us to your team and find out while 100’s of customers,
and dealers throughout the world have partnered with
Maintenance Connection Canada.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "/files/2020Dealers.pdf"
      }}>{`2020.01.01 Dealer flyer`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      